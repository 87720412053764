import React from "react";
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';
import 'moment/min/locales';
import Countdown from '../components/Countdown.component'

export default function StatusBusy({ event,syncComponentData,syncOnAirEvent }) {

    const duration = () => {
        const start = Moment(event.start.dateTime);
        const end = Moment(event.end.dateTime);

        return end.diff(start,'minutes');
    }

    const tillTime = Moment(event.end.dateTime).format("HH:mm");

    return (
        <React.Fragment>
            <Typography variant="h1" style={{color:'#e53935', fontWeight: 'bold'}}>PRENOTATA</Typography>
            <Typography variant="h2">{event.organizer.emailAddress.name}</Typography>
            <Countdown timeTillDate={tillTime} timeFormat="HH:mm" duration={duration()} syncOnAirEvent={syncOnAirEvent} syncComponentData={syncComponentData}/>
        </React.Fragment>
    );
}