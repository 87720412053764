import React from 'react';
import moment from 'moment';
import './Countdown.style.scss';
import { diffYMDHMS } from '../helpers/common.helper'

class Countdown extends React.Component {
    state = {
        hours: 0,
        minutes: 0,
        seconds: 0
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            const { timeTillDate, timeFormat } = this.props;
            this.setState(diffYMDHMS(moment(timeTillDate, timeFormat), moment()));
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    // componentDidUpdate() {
    //     const { hours, minutes, seconds } = this.state;

    //     if (hours === 0 && minutes === 0 && seconds === 0) {
    //         clearInterval(this.interval);
    //     }
    // }

    render() {
        let { hours, minutes, seconds } = this.state;
        const { duration } = this.props;

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;
        const timeToSeconds = moment(hours + ":" + minutes + ":" + seconds, 'HH:mm:ss').diff(moment().startOf('day'), 'minutes');
        const radius = mapNumber(timeToSeconds, duration, 0, 0, 359);

        return (
            <div className="countdown-container">
                <SVGCircle radius={radius} />
                <span className="countdown-label countdown-label-time">Si libera tra</span>
                <span className="countdown-label countdown-time">{hours}:{minutes}:{seconds}</span>
            </div>
        );
    }
}

const SVGCircle = ({ radius }) => (
    <svg className="countdown-svg">
        <path
            fill="none"
            stroke="#333"
            strokeWidth="25"
            d={describeArc(140, 140, 100, 0, radius)}
        />
    </svg>
);

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
    };
}

function describeArc(x, y, radius, startAngle, endAngle) {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    var d = [
        'M',
        start.x,
        start.y,
        'A',
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y
    ].join(' ');

    return d;
}

function mapNumber(number, in_min, in_max, out_min, out_max) {
    return (
        ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
    );
}

export default Countdown;