import React from "react";
import Typography from '@material-ui/core/Typography';
import './StatusFree.style.scss';

export default function StatusFree({syncComponentData,syncOnAirEvent,eventsToday,token}) {
    return (
        <div className="container-statusFree">
            <Typography variant="h1" style={{color:'green', fontWeight: 'bold'}}>LIBERA</Typography>
        </div>
    );
}