import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
    root: {

        padding: theme.spacing(0.5),
        margin: '40px',
        '& .MuiSnackbarContent-root': {
            backgroundColor: theme.palette.error.dark,
        }
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function SimpleSnackbar({ message }) {
    const classes = useStyles();

    return (
        <div>
            <Snackbar
                className={classes.root}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={true}
                autoHideDuration={null}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={
                    <React.Fragment>
                        <ErrorIcon /><br />
                        <span id="message-id">{message}</span>
                    </React.Fragment>
                }
            />
        </div>
    );
}