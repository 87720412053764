import React, { Component } from "react";
import Moment from 'moment';
import 'moment/min/locales';
import Typography from '@material-ui/core/Typography';
import './DateTime.style.scss';
import logoSala from '../assets/images/bg_humingbird.png';

class DateTime extends Component {
    constructor() {
        super();

        this.state = {
            dateTime: Moment()
        }
    }

    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    tick() {
        this.setState({ dateTime: Moment() });
        
        if(this.state.dateTime.format('mm') === '01' && this.state.dateTime.format('ss') === '01'){
            this.props.syncComponentData();
        }else if(this.state.dateTime.format('ss') === '01'){
            this.props.syncOnAirEvent();
        }
    }

    render() {

        const moment = this.state.dateTime.locale('it');
        const date = moment.format('dddd D MMMM').toUpperCase();
        const time = moment.format('HH:mm:ss');

        return (
            <div className="container-dateTime">
                <img src={logoSala} width="50" alt="logo-sala" />
                <Typography variant="h5">
                    <b>{time}</b> {date}
                </Typography>
            </div>
        );
    }
}

export default DateTime;