import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './home.style.scss';
import DateTime from '../components/DateTime.component';
import Status from '../components/Status.component';
import Events from '../components/Events.component'
import Moment from 'moment';
import { syncOnAirEvent,syncEventsToday } from '../helpers/common.helper';
import { syncAPIData } from '../helpers/api.helper';
import MenuAppBar from "../components/MenuAppBar.component";
import ErrorMessage from '../components/ErrorMessage.component';
import RoomDetails from '../components/RoomDetails.component';

class Home extends Component {

    constructor() {
        super();

        this.state = {
            eventsToday: [],
            eventsTomorrow: [],
            onAirEvent: {},
            error: {}
        }
    }

    syncComponentData = async () => {
        await syncAPIData(this.props.token).then(({ eventsToday, eventsTomorrow }) => {
            this.setState({ eventsToday, eventsTomorrow });
        }).catch((error) => {
            this.setState({ error: error.response.data });
        });

        await this.syncOnAirEvent();
    }

    syncOnAirEvent = async () => {
        await syncOnAirEvent(this.state.eventsToday).then((onAirEvent) => {
            this.setState({ onAirEvent });
        });
    }

    syncEventsToday = async () => {
        await syncEventsToday(this.state.eventsToday).then((eventsToday) => {
            this.setState({ eventsToday });
        });
    }

    async componentDidMount() {
        await this.syncComponentData();
    }

    render() {
        const { eventsToday, eventsTomorrow, onAirEvent, error } = this.state;
        const tomorrow = Moment().add(1, 'days').locale('it').format('dddd D MMM').toString();

        return (
            <React.Fragment>
                <MenuAppBar handlerLogOut={this.props.handlerLogOut} token={this.props.token}/>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={9}>
                            <Paper className="container-center-content">
                                <DateTime syncEventsToday={this.syncEventsToday} syncOnAirEvent={this.syncOnAirEvent} syncComponentData={this.syncComponentData}/>
                                <Status token={this.props.token} syncOnAirEvent={this.syncOnAirEvent} onAirEvent={onAirEvent} eventsToday={eventsToday} syncComponentData={this.syncComponentData} />
                            </Paper>
                        </Grid>
                        <Grid xs={3} item>
                            <RoomDetails/>
                            <Events events={eventsToday} title={"Oggi"} onAirEvent={onAirEvent} />
                            <Events events={eventsTomorrow} title={tomorrow} />
                        </Grid>
                    </Grid>
                </Container>
                {(error.message) ? <ErrorMessage message={error.message} /> : ""}
            </React.Fragment >
        );
    }
}

export default Home;