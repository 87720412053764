import React from "react";
import 'moment/min/locales';
import Event from './Event.component';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import _ from 'lodash';


const useStyles = makeStyles({
    card: {
        minWidth: 275,
        marginBottom: 20,
        backgroundColor: '#f5f5f5',
    },
    cardContent:{
        height: 185,
        overflow: 'auto',
        padding: '0 20px'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 20,
        textTransform: 'uppercase',
        fontWeight:'bold',
        backgroundColor:'#e8e7e7',
        // margin: '0 -20px',
        padding: '4px 18px',
        // marginBottom: '12px',
    },
    pos: {
        marginBottom: 12,
    },
    noEvents: {
        marginTop: '15px',
        fontStyle: 'italic',
        fontSize: '14px'
    },
    list:{
        padding:0
    }
});

export default function Events({ events, title, onAirEvent = {} }) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {title}
            </Typography>
            <CardContent className={classes.cardContent}>
                {
                    (_.isEmpty(events))
                        ?
                        <Typography className={classes.noEvents} color="textSecondary" gutterBottom>
                            Non ci sono eventi programmati
                    </Typography>
                        :
                        <List className={classes.list}>
                            {events.map((event) => <Event event={event} key={event.id} onAirEvent={onAirEvent} />)}
                        </List>
                }
            </CardContent>
        </Card>
    );
}