import React from "react";
import Chip from '@material-ui/core/Chip';
import PhoneBluetoothSpeakerIcon from '@material-ui/icons/PhoneBluetoothSpeaker';
import DoneIcon from '@material-ui/icons/Done';
import PeopleIcon from '@material-ui/icons/People';
import ClearIcon from '@material-ui/icons/Clear';
import TvIcon from '@material-ui/icons/Tv';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            // margin: theme.spacing(0.8),
            marginRight: '10px',
            marginBottom: '10px',
            padding: '0 7px 0 7px'
        },
    },
}));

export default function RoomDetails() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Chip
                icon={<PeopleIcon />}
                label="2-4"
                variant="outlined"
            />
            <Chip
                icon={<PhoneBluetoothSpeakerIcon />}
                variant="outlined"
                label={<DoneIcon />}
            />
            <Chip
                icon={<TvIcon />}
                variant="outlined"
                label={<ClearIcon />}
            />
        </div>
    );
}