import Moment from 'moment';
import _ from 'lodash';

export function isBusy(event) {
    return true;
}

export const syncOnAirEvent = async (events) => {
    const now = Moment();

    const onAirEvent = _.first(events.filter((event) => {
        let start = Moment(event.start.dateTime);
        let end = Moment(event.end.dateTime);
        // console.log(start.format('D-M-Y HH:mm:ss'),end.format('D-M-Y HH:mm:ss'),now.format('D-M-Y HH:mm:ss'),now.isBetween(start, end,'(]'))
        return now.isBetween(start, end, '(]');
    }));

    return _.isUndefined(onAirEvent) ? {} : onAirEvent;
}

export const syncEventsToday = async (events) => {
    const now = Moment();

    const futureEvents = events.filter((event) => {
        let end = Moment(event.end.dateTime);

        return end.isAfter(now);
    });

    return futureEvents;
}

export const getEventLength = (events) => {
    return events.length;
}

export function diffYMDHMS(date1, date2) {

    let years = date1.diff(date2, 'year');
    date2.add(years, 'years');

    let months = date1.diff(date2, 'months');
    date2.add(months, 'months');

    let days = date1.diff(date2, 'days');
    date2.add(days, 'days');

    let hours = date1.diff(date2, 'hours');
    date2.add(hours, 'hours');

    let minutes = date1.diff(date2, 'minutes');
    date2.add(minutes, 'minutes');

    let seconds = date1.diff(date2, 'seconds');

    // console.log(years + ' years ' + months + ' months ' + days + ' days ' + hours + ' hours ' + minutes + ' minutes ' + seconds + ' seconds'); 

    return { years, months, days, hours, minutes, seconds };
}

export function checkRoomStatus() {
    return console.log("to_update");
}