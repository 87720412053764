import React from "react";
import Moment from 'moment';
import 'moment/min/locales';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

const useStyles = makeStyles({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function Event({ event, onAirEvent }) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography variant="h6" component="h2">
            {(event.id === onAirEvent.id) ? <RadioButtonCheckedIcon style={{ color: '#e53935',verticalAlign: 'middle', fontSize: '15px' }}/> : ""} {Moment(event.start.dateTime).format('HH:mm')} <ArrowRightAltIcon style={{ verticalAlign: 'middle' }} /> {Moment(event.end.dateTime).format('HH:mm')}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
                {event.subject} 
            </Typography>
        </React.Fragment>
    );
}