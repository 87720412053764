import React from "react";
import {
  Route,
  Redirect,
} from "react-router-dom";

export default function PrivateRoute({ children, ...rest }) {
    return (
      
      <Route
        {...rest}
        render={({ location }) =>
        rest.state.isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }