import axios from 'axios';

export const API_GET = "get";
export const API_POST = "post";
export const API_URL = process.env.REACT_APP_API_URL;
export const API_CALENDAR_DAILY = "calendarDaily";
export const API_CALENDAR_TOMORROW = "calendarTomorrow";
export const API_CHECK_CALENDAR_DAILY = "calendarDailyIsChanged";
export const API_ME= "me";
export const API_LOGIN = "login";

export const makeGetRequest = (endpoint, token = "") => {
    return axios.get(API_URL + endpoint, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });
}

export const makePostRequest = (endpoint, data = {}, token = "") => {
    return axios.post(API_URL + endpoint, data, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });
}

export function getAPICalendarDaily(token) {
    return makeGetRequest(API_CALENDAR_DAILY, token).then(res => {
        return res.data;
    });
}

export function getAPICalendarTomorrow(token) {
    return makeGetRequest(API_CALENDAR_TOMORROW, token).then(res => {
        return res.data;
    });
}

export function getMe(token) {
    return makeGetRequest(API_ME, token).then(res => {
        return res.data;
    });
}

export function checkDailyIsChanged(token, eventLength) {
    return makePostRequest(API_CHECK_CALENDAR_DAILY,{eventLength}, token).then(res => {
        return res.data;
    });
}

export async function syncAPIData(token) {
    const eventsToday = await getAPICalendarDaily(token);
    const eventsTomorrow = await getAPICalendarTomorrow(token);

    return { eventsToday, eventsTomorrow }
}

