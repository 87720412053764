import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import LoginPage from './pages/login.page';
import Home from './pages/home.page';
import PrivateRoute from './components/PrivateRoute.component';
import cookie from 'react-cookies';

export default class App extends Component {

  constructor() {
    super();

    this.state = {
      token: "",
      isAuthenticated: false
    }
  }

  componentDidMount() {
    if (cookie.load('token')) {
      this.handlerToken(cookie.load('token'));
    }
  }

  handlerToken = (token) => {
    this.setState({ token: token, isAuthenticated: true });
  }

  handlerLogOut = () => {
    cookie.remove('token', { path: '/' })
    this.setState({ token: "", isAuthenticated: false });
  }

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <PrivateRoute path="/home" state={this.state}>
              <Home token={this.state.token} handlerLogOut={this.handlerLogOut}/>
            </PrivateRoute>
            <Route path="/">
              <LoginPage state={this.state} handlerToken={(token) => this.handlerToken(token)} />
            </Route>
          </Switch>
        </div>
      </Router >
    );
  }
}