import React, { useState } from "react";
import {
    useHistory,
    useLocation,
    Redirect
} from "react-router-dom";

import axios from 'axios';
import cookie from 'react-cookies';
import { API_URL, API_LOGIN } from '../helpers/api.helper';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import './login.style.scss';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#bad739',
        '&:hover': {
            backgroundColor: '#bad739',
        }
    },
    root: {
        '& label.Mui-focused': {
            color: 'black',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#bad739',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#bad739',
            },
            '&:hover fieldset': {
                borderColor: '#bad739',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#bad739',
            },
        },
    },
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © bastaentrareacaso.net '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function LoginPage(props) {
    let history = useHistory();
    let location = useLocation();
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    let { from } = location.state || { from: { pathname: "/home" } };

    let handlerSubmit = (evt) => {
        evt.preventDefault();

        if (cookie.load('token')) {
            props.handlerToken(cookie.load('token'));
            history.replace(from);
        } else {
            axios.post(API_URL + API_LOGIN, { email, password })
                .then(res => {
                    cookie.save('token', res.data.success.token, { path: '/' })
                    props.handlerToken(res.data.success.token);
                })
                .then(res => {
                    history.replace(from);
                })
                .catch(error => {
                    alert(JSON.stringify(error))
                });
        }
    }

    return (
        props.state.isAuthenticated
            ?
            <Redirect to="/home" />
            :
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    {/* <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar> */}
                    <Typography variant="h6">
                        Inserisci le credenziali della SALA
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={handlerSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            autoFocus
                            onChange={e => setEmail(e.target.value)}
                            className={classes.root}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            autoComplete="current-password"
                            className={classes.root}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                        >
                            Sign In
          </Button>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>

    );
}

