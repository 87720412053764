import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Link from '@material-ui/core/Link';
import { getMe } from '../helpers/api.helper';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: '100px',
        '& .MuiToolbar-root': {
            minHeight: '24px',
            backgroundColor: '#333',
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    typography: {
        flexGrow: 1,
        fontWeight: 'bold',
        alignSelf: 'flex-end',
        fontSize: '14px',
    },
    button: {
        margin: theme.spacing(1),
    },
    link: {
        color: 'white'
    }
}));

export default function MenuAppBar({handlerLogOut,token}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [user, setUser] = useState("");
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        getMe(token).then(res => {
            setUser(res);
        });
    },[token]);

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Typography align="right" className={classes.typography}>{user.name} | <Link href="#" className={classes.link} onClick={handlerLogOut}>LogOut</Link>
                    </Typography>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>LogOut</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </div>
    );
}